import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Page = () => (
	<Layout>
		<SEO title="Sobre" />
		<div className="uk-card uk-card-default uk-card-body">
			<h2>A empresa</h2>A <strong>ISTM Instrumentação e Controle Ltda.</strong>{' '}
			é uma empresa especializada na venda de instrumentos para medição,
			controle e registro de: Temperatura, umidade, pressão, gases de combustão,
			rotação, velocidade/qualidade do ar, luz, som, grandezas elétricas, entre
			outros. Nossa empresa conta com a orientação e experiência de competentes
			profissionais que atuam neste segmento de mercado a vários anos. Atendemos
			a grandes grupos nacionais e internacionais, como Petrobrás, indústrias
			farmacêuticas, indústrias químicas, indústrias alimentícias, laboratórios
			de calibração, universidades, institutos de pesquisa, profissionais
			liberais, entre outros. Este site foi desenvolvido especialmente para
			você, permitindo visualizar todos os nossos produtos através do catálogo
			virtual e ainda estabelecer um contato direto para solicitar cotações, ou
			maiores informações técnicas.
		</div>
	</Layout>
)
export default Page
